import React, { useState } from "react";
import "../styles/contactstyle.css"; // Adjust the path if needed

interface ContactModalProps {
  show: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({ show, onClose }) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [request, setRequest] = useState<string>("");
  const [captchaValid, setCaptchaValid] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (captchaValid) {
      // You would send form data to your backend API here
      const formData = { name, email, request };
      console.log("Form Submitted: ", formData);

      // Reset form fields
      setName("");
      setEmail("");
      setRequest("");
      setCaptchaValid(false); // Reset captcha
    } else {
      alert("Please complete the CAPTCHA.");
    }
  };

  const handleCaptchaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaptchaValid(e.target.checked); // Simple validation for demo purposes
  };

  if (!show) return null;

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="modal">
        <div className="modal-header">
          <h2>Contact Us</h2>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>

            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>

            <label>
              Request:
              <textarea
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                required
              />
            </label>

            {/* Simple CAPTCHA Placeholder */}
            <div className="captcha-row">
              <label>
                <input
                  type="checkbox"
                  checked={captchaValid}
                  onChange={handleCaptchaChange}
                  required
                />
                I am not a robot
              </label>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
