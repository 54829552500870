import React from "react";
import ReactDOM from "react-dom/client"; // React 18 uses this for createRoot
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/AuthConfig";
import App from "./components/App";

// Initialize the MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Handle redirect response
msalInstance
	.handleRedirectPromise()
	.then((response) => {
		if (response)  {
			console.log("Login Successful", response);
		}
	})
	.catch(error => {
	console.error("Error during authentication redirect: ", error);
	});

// Get the root element from the DOM
const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement); // React 18 way to create root

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  );
} else {
  console.error("Root element not found!");
}
