import {
  PublicClientApplication,
  Configuration,
  LogLevel,
} from "@azure/msal-browser";

// MSAL.js configuration for your Azure AD app
export const msalConfig: Configuration = {
  auth: {
    clientId: "593f77cd-08c3-4fba-9f5b-e50dcd0c597b", // Azure AD Client ID
    authority: "https://login.microsoftonline.com/2717bf88-856c-47a8-bd7d-d11593ec2cb2", // Azure AD Tenant
    redirectUri: "https://thebasemen.tech:443", // Adjust the redirect URI based on your environment
  },
  cache: {
    cacheLocation: "sessionStorage", // Temporarily store tokens during exchange
    storeAuthStateInCookie: false, // Set to true if targeting older browsers
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel: LogLevel, message: string) => {
        console.log(message);
      },
      logLevel: LogLevel.Info,
    },
  },
};

// Create an instance of PublicClientApplication for MSAL
const msalInstance = new PublicClientApplication(msalConfig);

// Function to handle user login
export const login = async () => {
  try {
    const loginResponse = await msalInstance.loginPopup({
      scopes: ["openid", "profile", "email"], // Define the scopes you're requesting
    });

    // Extract the access token
    const accessToken = loginResponse.accessToken;

    // Send the authorization code to the back end for token exchange
    await fetch('/api/store-token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: accessToken }),
    });

    console.log('Login successful', loginResponse);
  } catch (error) {
    console.error('Login failed:', error);
  }
};

// Function to handle user logout
export const logout = async () => {
  try {
    await msalInstance.logout();
    console.log("Logout successful");
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

// Function to retrieve the access token
export const getAccessToken = async () => {
  try {
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["openid", "profile", "email"], // Define scopes for the token request
    });

    return tokenResponse.accessToken;
  } catch (error) {
    console.error("Failed to acquire access token:", error);
    throw error;
  }
};

export default msalInstance;
