import React from "react";
import "../styles/infomodal.css"; // Adjust the path if needed

interface AboutModalProps {
  show: boolean;
  onClose: () => void;
}

const AboutModal: React.FC<AboutModalProps> = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="modal">
        <div className="modal-header">
          <h2>About Me</h2>
          <button onClick={onClose} className="close-button">
            X
          </button>
        </div>
        <div className="modal-body">
          <p>Just a guy looking to over complicate his homelab.</p>
          <p>
            If I know you and you expect to use the functionality of this
            website, you know what to do. If something isn't working, or I asked
            you to, please complete the Request Form.
          </p>
          <p>Thanks!</p>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;
