// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #20232a;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-shadow: 5px 5px 5px #000;
}

.close-button {
  background: none;
  border: none;
  position: absolute;
  top: 17px;
  right: 17px;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  text-shadow: 4px 4px 4px #000;
}

.modal-body {
  margin: 0;
  padding: 10px;
  text-align: center; /* Center the text */
  line-height: 1.6; /* Optional: Make the text more readable by adjusting line height */
}

.modal-body p:not(:last-child) {
  margin-bottom: 1rem; /* Adjust space between paragraphs */
}
`, "",{"version":3,"sources":["webpack://./src/styles/infomodal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,eAAe;EACf,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,SAAS;EACT,aAAa;EACb,kBAAkB,EAAE,oBAAoB;EACxC,gBAAgB,EAAE,mEAAmE;AACvF;;AAEA;EACE,mBAAmB,EAAE,oCAAoC;AAC3D","sourcesContent":[".modal-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100vw;\r\n  height: 100vh;\r\n  background: rgba(0, 0, 0, 0.5);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 1000;\r\n}\r\n\r\n.modal {\r\n  background-color: #20232a;\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  max-width: 500px;\r\n  width: 100%;\r\n  position: relative;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);\r\n}\r\n\r\n.modal-header {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 10px;\r\n  text-shadow: 5px 5px 5px #000;\r\n}\r\n\r\n.close-button {\r\n  background: none;\r\n  border: none;\r\n  position: absolute;\r\n  top: 17px;\r\n  right: 17px;\r\n  font-size: 1rem;\r\n  cursor: pointer;\r\n  color: white;\r\n  text-shadow: 4px 4px 4px #000;\r\n}\r\n\r\n.modal-body {\r\n  margin: 0;\r\n  padding: 10px;\r\n  text-align: center; /* Center the text */\r\n  line-height: 1.6; /* Optional: Make the text more readable by adjusting line height */\r\n}\r\n\r\n.modal-body p:not(:last-child) {\r\n  margin-bottom: 1rem; /* Adjust space between paragraphs */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
