import React, { useState } from "react";
import "../styles/styles.css";
import ContactModal from "../modals/contactmodal"; // Import the Contact modal
import AboutModal from "../modals/infomodal"; // Import the About modal
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

const App: React.FC = () => {
  // State for handling modals' visibility
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showAboutModal, setShowAboutModal] = useState<boolean>(false);

  const { instance } = useMsal(); // Get the Msal instance
  const isAuthenticated = useIsAuthenticated(); //Check Authentication status

  const openContactModal = () => {
    setShowContactModal(true);
  };

  const openAboutModal = () => {
    setShowAboutModal(true);
  };

  const closeContactModal = () => {
    setShowContactModal(false);
  };

  const closeAboutModal = () => {
    setShowAboutModal(false);
  };

  const handleLogin = () => {
	instance.loginRedirect({
		scopes: ["openid","profile","User.Read"], //Adjust scopes as needed
	});
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="top-bar">
          {/* Hamburger menu */}
          <div className="hamburger-menu">
            <div className="hamburger-icon">
              <div></div>
              <div></div>
              <div></div>
            </div>

            {/* The dropdown menu will now appear on hover */}
            <div className="dropdown-menu">
              {/* These links open the modals */}
              <a href="#" onClick={openContactModal}>
                Contact
              </a>
              <a href="#" onClick={openAboutModal}>
                About Us
              </a>
            </div>
          </div>

          {/* Logo with link */}
          <div className="logo">
            <a href="/" className="logo-link">
              thebasement.tech
            </a>
          </div>

          {/* Login button */}
          <div className="login-button">
            {isAuthenticated ? (
		<button onClick={() => instance.logoutRedirect()}>Logout</button>
	   ) : (
		<button onClick={() => instance.loginRedirect({
		  scopes: ["openid", "profile", "User.Read"],
		})}>Login</button>
	   )}
          </div>
        </div>
      </header>

      {/* Main content */}
      <main className="App-main">
        <div className="gif-container">
          <img 
            src="/assets-new/images/background-main.gif"  // This is a direct path for public folder
            alt="Looping GIF"
            className="looping-gif" />
        </div>
      </main>

      {/* Contact Modal */}
      <ContactModal show={showContactModal} onClose={closeContactModal} />

      {/* About Modal */}
      <AboutModal show={showAboutModal} onClose={closeAboutModal} />
    </div>
  );
};

export default App;
